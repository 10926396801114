import localforage from 'localforage';

localforage.config({
	name: 'dredition',
	storeName: 'store'
});

/**
 * In-memory store for synchronous reads
 * @constant {Map}
 */
const memstore = new Map();

/**
 * Used to determine if init has been run
 * @type {boolean}
 */
let isInitiated = false;

/**
 * Promise cache
 * @type {Promise}
 */
let initPromise;

/**
 * Check if init has been run
 * @param {string} name
 * @param {string} key
 */
function pre(name, key) {
	// Skip this check when running tests
	if (globalThis.testMode) {
		return;
	}

	if (!isInitiated) {
		throw Error(`storage.init() must be called before storage.${name}() (key = ${key})`);
	}
}

export default {
	/**
	 * Initialize in-memory store from persisted storage
	 * @returns {Promise}
	 */
	async init() {
		if (initPromise) {
			return initPromise;
		}

		initPromise = localforage.iterate((value, key) => {
			memstore.set(key, value);
		});

		await initPromise;
		isInitiated = true;
	},

	/**
	 * Gets an item from the storage library
	 * @param {string} key
	 * @param {any} defaultValue
	 * @returns {any}
	 */
	get(key, defaultValue) {
		pre('get', key);
		if (!memstore.has(key)) {
			return defaultValue;
		}
		return memstore.get(key);
	},

	/**
	 * Check if item with key exists in storage
	 * @param {string} key
	 * @returns {boolean}
	 */
	has(key) {
		pre('has', key);
		return memstore.has(key);
	},

	/**
	 * Saves data to an offline store
	 * @param {string} key
	 * @param {any} data
	 * @returns {Promise}
	 */
	set(key, data) {
		pre('set', key);
		memstore.set(key, data);

		if (globalThis.testMode) {
			return Promise.resolve();
		}

		return localforage.setItem(key, data);
	},

	/**
	 * Removes the value of a key from the offline store
	 * @param {string} key
	 * @returns {Promise}
	 */
	remove(key) {
		pre('remove', key);
		memstore.delete(key);
		return localforage.removeItem(key);
	},

	/**
	 * Removes every key from the database, returning it to a blank slate
	 * @returns {Promise}
	 */
	clear() {
		memstore.clear();
		return localforage.clear();
	}
};

/**
 * Cleanup unused or old entries from edition collapsed groups
 *
 * @param {string} editionId
 * @param {object} groupsByMetaId
 * @param {object} collapsedGroups
 * @returns {object}
 */
export function cleanupEditionCollapsedGroups(editionId, groupsByMetaId, collapsedGroups) {
	if (collapsedGroups[editionId]) {
		// update timestamp for the given edition every time to prevent it from being cleaned up
		collapsedGroups[editionId].updatedAt = Date.now();
	}

	return Object.entries(collapsedGroups).reduce((acc, [id, {updatedAt, groupIds}]) => {
		if (updatedAt < Date.now() - 1000 * 60 * 60 * 24 * 30) {
			// remove edition entry older than 1 month for all editions
		} else if (id === editionId) {
			// remove entries for non-existing groups for the given edition
			const validGroupIds = groupIds.filter((groupMetaId) => groupsByMetaId[groupMetaId]);

			if (validGroupIds.length) {
				acc[id] = {updatedAt, groupIds: validGroupIds};
			}
		} else {
			acc[id] = {updatedAt, groupIds};
		}
		return acc;
	}, {});
}
