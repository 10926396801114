import * as Sentry from '@sentry/browser';
import BaseError from '../lib/BaseError';
import ExpectedError from '../lib/ExpectedError';
import HTTPError from '../lib/HTTPError';

export default function errorHandler(err, options) {
	if (!err || err.isHandled) {
		return err;
	}

	options = Object.assign({}, {
		silent: false
	}, options);

	err = parseError(err);

	if (shouldLogError(err)) {
		if (!options.silent) {
			console.error(err); // eslint-disable-line no-console
			Sentry.addBreadcrumb({
				message: err.message,
				category: 'debug',
				data: err.meta
			});
		}

		logError(err, options.extra);
	}

	err.isHandled = true;

	return err;
}

export function parseError(err) {
	const unhandledRejection = parseUnhandledRejection(err);

	if (unhandledRejection) {
		err = unhandledRejection;
	}

	if (typeof err === 'string') {
		err = new BaseError(err);
	}

	if (!(err instanceof HTTPError) && isHttpError(err)) {
		err = new HTTPError(err);
	}

	if (unhandledRejection) {
		err.message = `Unhandled rejection: ${err.message}`;
	}

	if (!err.userMessage) {
		err.userMessage = 'An unexpected error occurred';
	}

	return err;
}

export function isHttpError(err) {
	return ['config', 'data', 'status', 'statusText']
		.every((prop) => err.hasOwnProperty(prop));
}

export function isExpectedError(err) {
	return err instanceof ExpectedError;
}

/**
 * We ignore errors that are expected, and all HTTP errors
 * @param {Error} err
 * @returns {boolean}
 */
export function shouldLogError(err) {
	return !isExpectedError(err) && !(err instanceof HTTPError);
}

function logError(err, extra) {
	Sentry.withScope((scope) => {
		if (extra) {
			scope.setExtras(extra);
		}

		const eventId = Sentry.captureException(err);

		err.eventId = eventId;
	});
}

function parseUnhandledRejection(err) {
	if (typeof err === 'string' && err.match(/^Possibly unhandled rejection/)) {
		try {
			return JSON.parse(err.replace('Possibly unhandled rejection: ', ''));
		} catch (err) {}
	}
}
