import * as Sentry from '@sentry/browser';
import {isTokenExpired} from './jwt';
import storage from './storage';

export function getUser() {
	return storage.get('user');
}

export function setUser(user) {
	return storage.set('user', user);
}

export function getAccount() {
	return storage.get('account');
}

export function setAccount(account) {
	return storage.set('account', account);
}

export function setFirebaseConfig(conf) {
	return storage.set('firebaseConfig', conf);
}

export function getFirebaseConfig() {
	return storage.get('firebaseConfig');
}

export function getToken() {
	return storage.get('jwtAuthToken');
}

export function setToken(token) {
	return storage.set('jwtAuthToken', token);
}

export async function clearCredentials() {
	await storage.remove('jwtAuthToken');
	await storage.remove('user');
	await storage.remove('firebaseConfig');
	await storage.remove('account');
}

export function isAuthenticated() {
	const token = getToken();
	return Boolean(token && !isTokenExpired(token));
}

export async function redirectToLogin(location, skipRedirect, message) {
	if (!skipRedirect) {
		await setLoginRedirectTo(location.hash.replace('#', ''));
	}

	if (!window.testMode) {
		location.href = location.toString().replace(/(.*:\/\/[^\/]+).*$/, `$1/login.html${message ? `#fail=${message}` : ''}`);
	}
}

export async function redirectToApp(location) {
	const redirectTo = getLoginRedirectTo();
	await setLoginRedirectTo(''); // clear after use

	if (!window.testMode) {
		location.href = location.toString().replace(/(.*:\/\/[^\/]+).*$/, '$1') + '#' + redirectTo;
	}
}

function setLoginRedirectTo(url) {
	return storage.set('loginRedirectTo', url);
}

function getLoginRedirectTo() {
	return storage.get('loginRedirectTo');
}

export async function logout() {
	// replicating auth.service logout when angular access is missing
	await clearCredentials();
	Sentry.setUser({});
}

export function getClientId(config, hostname) {
	if (config.clientId) {
		return config.clientId;
	}

	const hostnameParts = hostname.split('.');

	// customer.dredition.aptoma.no -> customer
	if (hostnameParts.length === 4) {
		return hostnameParts[0];
	}

	return null;
}
