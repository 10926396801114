// https://bowser-js.github.io/bowser-online/
import bowser from 'bowser';

const parser = bowser.getParser(window.navigator.userAgent);

export function isSupported() {
	const name = parser.getBrowserName();

	return name === 'Chrome' ||
		name === 'Safari' ||
		name === 'Firefox' ||
		name === 'Microsoft Edge';
}

export function isOutdated() {
	return !parser.satisfies({
		chrome: '>=92',
		safari: '>=15.4',
		firefox: '>=90',
		edge: '>=92'
	});
}

export const browser = parser.getBrowser();
export const os = parser.getOS();
export const platform = parser.getPlatform();
export const engine = parser.getEngine();
export const isMobile = platform.type === 'mobile';
