import BaseError from './BaseError';

export default class HTTPError extends BaseError {
	constructor(res) {
		super();
		this.name = 'HTTPError';
		this.message = `${res?.config?.method} ${res?.config?.url}`;
		if (typeof (res.status) === 'number') {
			this.message += ` ${res.status}`;
		}
		if (res.statusText) {
			this.message += ` (${res.statusText})`;
		}
		this.userMessage = getMessage(res);
		this.config = res.config;
		this.data = res.data;
		this.meta = res.data?.errors?.[0]?.meta;
		this.headers = res.headers;
		this.status = res.status;
		this.statusText = res.statusText;
	}
}

function getMessage({status, data}) {
	if (status === undefined) {
		return 'Request canceled';
	}

	if (status === -1 || status === 0) {
		return 'Network connection refused, try again later';
	}

	if (data) {
		if (data.errors && data.errors[0]) {
			let msg = data.errors[0].title;

			if (data.errors[0].details) {
				msg += ` - ${data.errors[0].details}`;
			}

			return msg;
		}

		if (data.error) {
			if (data.message) {
				return data.message;
			}

			return `Request failed with status ${status} (${data.error})`;
		}
	}

	return `Request failed with status ${status}`;
}
