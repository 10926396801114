import * as Sentry from '@sentry/browser';
import {platform} from './browser';
import {shouldLogError} from './error-handler';
import storage from './storage';

/**
 * List of ignored ResizeObserver errors
 *
 * @see https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
 */
const ignoredResizeObserverErrors = [
	'ResizeObserver loop limit exceeded',
	'ResizeObserver loop completed with undelivered notifications.'
];

/**
 * List of ignored errors (mostly related to fetch)
 */
const ignoredErrors = [
	[TypeError, 'Failed to fetch'],
	[TypeError, 'fetch failed'],
	[TypeError, 'NetworkError when attempting to fetch resource.'],
	[Error, 'A network error (such as timeout, interrupted connection or unreachable host) has occurred.']
];

/**
 * List of ignored DOMException errors
 */
const ignoredDomExceptionErrors = [
	['AbortError', 'The operation was aborted.']
];

/**
 * Initialize Sentry
 *
 * @see https://docs.sentry.io/platforms/javascript/configuration/options/
 * @param {object} options
 * @param {string} options.sentryApiKey
 * @param {string} options.apiUrl
 * @param {string} options.version
 * @param {string} options.environment
 */
export function initSentry({sentryApiKey, version, environment, apiUrl}) {
	const user = storage.get('user');

	Sentry.init({
		dsn: `https://${sentryApiKey}@sentry.io/149136`,
		tunnel: `${apiUrl}/sentry`,
		release: version,
		autoSessionTracking: false,
		environment,
		debug: globalThis.appConfig.debug,
		beforeSend: (event, hint) => beforeSend(event, hint, platform.type)
	});

	if (user) {
		Sentry.setUser({
			email: user.email,
			username: user.name,
			id: user.id
		});
	}
}

/**
 * Called immediately before the event is sent to the server, this is your last chance to decide not to send data or to edit it.
 *
 * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#filtering-error-events
 * @param {object} event
 * @param {object} hint
 * @param {string} [platform]
 * @returns {null | object}
 */
export function beforeSend(event, hint, platform = '') {
	// The original exception that caused the Sentry SDK to create the event
	const error = hint.originalException;

	// Ignore errors from mobile/tablet devices
	if (platform === 'mobile' || platform === 'tablet') {
		return null;
	}

	// Check if we should ignore this error
	if (error && shouldIgnoreError(error)) {
		return null;
	}

	// Set the `fragment` tag because the `url` tag does not include the fragment.
	// This can be used in a Sentry query as follows: `fragment:*614312a9467e943bab68db94*`
	event.tags = event.tags || {};
	event.tags['fragment'] = document.location.hash;

	if (!error || shouldLogError(error)) {
		return event;
	}

	return null;
}

/**
 * Check if we should ignore the given error
 *
 * @param {object} error
 * @returns {boolean}
 */
function shouldIgnoreError(error) {
	// Skip ignored errors from ResizeObserver of a specific message
	if (ignoredResizeObserverErrors.includes(error.message || error)) {
		return true;
	}

	// Skip ignored errors of a specific type and message
	if (ignoredErrors.some(([errorType, message]) => error instanceof errorType && error.message === message)) {
		return true;
	}

	// Skip ignored DOMException errors of a specific name and message
	if (ignoredDomExceptionErrors.some(([name, message]) => error instanceof DOMException && error.name === name && error.message === message)) {
		return true;
	}

	return false;
}
