import errorHandler from './error-handler';
import {isTokenExpired} from './jwt';

/**
 * Return a renewed token if the given token is expired
 *
 * @param {string} token
 * @param {Function} [onSessionExpire]
 * @returns {Promise} - resolved with new token
 */
export async function refreshToken(token, onSessionExpire) {
	if (!token) {
		return;
	}

	// Autorenew JWT token 23 hours before it expires to avoid “Session timed out”.
	if (!isTokenExpired(token, 86400 - 3600)) {
		return;
	}

	try {
		// Don't use api.request() to prevent circular dependency and get more control of the request
		const res = await fetch(`${globalThis.appConfig.apiUrl}/auth/refresh-token`, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});

		if (res.status === 200) {
			const {token} = await res.json();
			return token;
		}

		if (res.status === 401 && onSessionExpire) {
			onSessionExpire();
		}
	} catch (err) {
		errorHandler(err);
	}
}
